import {RemixBrowser} from '@remix-run/react';
import { ParallaxController } from 'parallax-controller';
import {hydrateRoot} from 'react-dom/client';

// const parallaxController = ParallaxController.init({})

// parallaxController.update();
document.querySelectorAll('html > script').forEach((s:any) => {
  s.parentNode.removeChild(s);
  document.head.appendChild(s)
});

  hydrateRoot(
    document,
    <RemixBrowser />
  );
